.modal--full-screen {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 10;

  button {
    background-color: #005082;
    border-color: #005082;
    color: #fff;
  }

  .cancel-edit {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: bold;
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 28px;
    cursor: pointer;
  }
}
