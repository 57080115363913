.session-page {
  display: flex;
  align-items: stretch;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  background-color: #faf4f4;

  .body {
    flex: 1 1 auto;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: stretch;
    justify-content: flex-start;
    background-color: #edf3f6;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23b5c8d2' fill-opacity='0.4'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  .flex-card {
    flex: 1 1 210px;
    display: flex;
    flex-direction: column;
    min-height: 230px;
    max-width: 400px;
    min-width: 300px;
    // max-height: 250px;
    background-color: #eef1f4;
    padding: 8px;
  }

  .card-display {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card {
    width: 120px;
    height: 180px;
    background-color: #fdfdfd;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 6em;
    color: #3d424e;
    font-family: 'Baloo Thambi 2', Helvetica, Arial, sans-serif;
    border: 2px solid #4e5666;
    -webkit-box-shadow: 0px 4px 12px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 12px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 12px -3px rgba(0, 0, 0, 0.75);

    .card-pattern {
      display: block;
      width: 88%;
      height: 93%;
      border-radius: 4px;
      border: 2px solid #9cbde0;
      background-color: #c0ebda;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='%235084ba' fill-opacity='0.47' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E");
    }

    .card-border {
      width: 90%;
      height: 92%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #a7bdce;
      border-radius: 4px;
    }
  }

  .attendee-card.flex-card {
    padding: 0;
    background-color: rgb(65, 47, 122);
  }

  .attendee {
    text-align: center;
    font-weight: bold;
    font-family: 'Baloo Thambi 2', Helvetica, Arial, sans-serif;
    font-size: 18px;
    min-height: 32px;
    position: relative;
    .overlay {
      background-color: white;
      opacity: 0.7;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
    span {
      line-height: 32px;
      display: inline-block;
      position: relative;
      z-index: 10;
      color: #313d63;
      font-weight: normal;
    }
  }

  .summary-card {
    display: flex;
    flex-direction: column;
  }

  .attendee-summary {
    font-size: 0.8em;
  }

  .points-results {
    margin-top: 16px;
    flex: 1;
    overflow-y: auto;
    span {
      display: block;
    }

    .points-summary {
      th,
      td {
        padding: 8px 15px;
        text-align: center;
        border-bottom: none;
      }

      th {
        color: #444;
        padding-bottom: 4px;
      }

      td {
        padding-top: 4px;
      }

      th:nth-child(odd),
      td:nth-child(odd) {
        background-color: #e1e1e1;
      }
    }

    // ul {
    //   list-style-type: none;

    //   li {
    //     margin: 0;
    //     padding: 4px 14px;
    //     display: inline-block;
    //     text-align: center;
    //   }

    //   li:nth-child(odd) {
    //     background-color: #c8c8c8;
    //   }
    // }
  }

  h4 {
    font-size: 1.8rem;
    margin-bottom: 6px;
    color: #424242;
    letter-spacing: 0.03rem;
  }

  @media (max-width: 750px) {
    .flex-card {
      flex: 1 1 180px;
      min-height: 200px;
    }

    .card {
      width: 110px;
      height: 150px;
    }
  }
}

.display-hands-button,
.display-hands-button:hover {
  color: #27496d;
  border-color: #27496d;
}

.reset-hands-button,
.reset-hands-button:hover {
  color: #84142d;
  border-color: #84142d;
}
