.hand-button {
  position: fixed;
  z-index: 2;
  bottom: 8px;
  right: 8px;
  width: 62px;
  height: 62px;
  background-color: #fffdf9;
  border: 1px solid #f0ebe1;
  border-radius: 62px;
  line-height: 62px;
  text-align: center;
  -webkit-box-shadow: 0px 3px 12px 0px rgba(163, 178, 194, 1);
  -moz-box-shadow: 0px 3px 12px 0px rgba(163, 178, 194, 1);
  box-shadow: 0px 3px 12px 0px rgba(163, 178, 194, 1);
  cursor: pointer;

  &:hover,
  &:active {
    background-color: #fff4df;
  }

  img {
    height: 46px;
    margin-top: 5px;
  }
}

.hand-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 7;
  background-color: transparent;

  &.hand-backdrop--open {
    display: block;
  }

  &.hand-backdrop--closed {
    display: none;
  }
}

.hand-panel {
  position: absolute;
  bottom: 8px;
  right: 80px;
  width: 60vw;
  max-height: 40vh;
  padding: 8px;
  border-radius: 2px;
  background-color: #3282b8;
  z-index: 9;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  flex-wrap: wrap;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(92, 92, 92, 0.52);
  -moz-box-shadow: 0px 0px 5px 2px rgba(92, 92, 92, 0.52);
  box-shadow: 0px 0px 5px 2px rgba(92, 92, 92, 0.52);

  &.hand-panel--open {
    display: flex;
  }

  &.hand-panel--closed {
    display: none;
  }
}

.point-card {
  flex: 1 0 auto;
  min-width: 56px;
  height: 70px;
  border-radius: 3px;
  text-align: center;
  line-height: 70px;
  background-color: #f6eedf;
  margin: 4px 8px;
  font-size: 2rem;
  color: #475678;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(71, 71, 71, 0.47);
  -moz-box-shadow: 0px 0px 5px 2px rgba(71, 71, 71, 0.47);
  box-shadow: 0px 0px 5px 2px rgba(71, 71, 71, 0.47);

  &:hover {
    -webkit-box-shadow: 0px 1px 5px 3px rgba(71, 71, 71, 0.4);
    -moz-box-shadow: 0px 1px 5px 3px rgba(71, 71, 71, 0.4);
    box-shadow: 0px 1px 5px 3px rgba(71, 71, 71, 0.4);
    border: 1px solid #aeaaa2;
  }
}
