.header {
  height: 48px;
  background-color: #005082;
  flex: 0 0 48px;
  padding: 8px;
  z-index: 3;

  h2 {
    float: left;
    line-height: 32px;
    margin: 0;
    color: #dbe9f3;
    font-size: 2.3rem;
    letter-spacing: 0.05rem;
    font-family: 'Baloo Thambi 2', Helvetica, Arial, sans-serif;
  }

  .share-button {
    border: 1px solid #003352;
    background-color: transparent;
    text-align: center;
    padding: 0 12px;
    margin: 0;
    height: 28px;
    opacity: 0.7;
    margin-left: 12px;
    margin-top: 2px;

    &:hover {
      border: 1px solid white !important;
      opacity: 1;
    }

    img {
      height: 100%;
      width: 16px;
      margin-left: -3px;
    }
  }
}

.settings {
  float: right;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .settings-button {
    padding: 4px;
    border: 1px solid rgb(29, 42, 66);
    border-radius: 3px;
    margin: 0;
    height: 28px;
    line-height: 22px;
    color: white;
    position: relative;
    z-index: 9;
  }

  .settings-menu {
    position: relative;
    z-index: 10;
    background-color: white;
    border-radius: 6px;
    padding: 8px 0;
    width: 180px;
    list-style-type: none;
    -webkit-box-shadow: 0px 4px 12px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 12px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 12px -3px rgba(0, 0, 0, 0.75);

    li {
      margin: 0;
      padding: 8px;
      cursor: pointer;

      &:hover {
        background-color: rgb(236, 240, 243);
      }
    }

    &.settings-menu--closed {
      display: none;
    }

    &.settings-menu--open {
      display: block;
    }
  }

  .menu-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #444;
    z-index: 8;
    opacity: 0.5;

    &.menu-backdrop-display {
      display: block;
    }

    &.menu-backdrop-hide {
      display: none;
    }
  }
}
